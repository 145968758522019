<template>
    <div class="notifications">
        <router-view :nav-is-opened="navIsOpened"></router-view>

        <PageHeading :breadcrumbs="breadcrumbs" title="Send notification" />

        <Input
            id="title"
            v-model="title"
            type="text"
            name="title"
            label="Title"
            placeholder="Enter title"
        />

        <Input
            id="body"
            v-model="body"
            type="textarea"
            name="body"
            label="Body"
            placeholder="Enter text"
        />

        <Input
            id="url"
            v-model="url"
            type="text"
            name="url"
            label="Url"
            placeholder="Enter url"
            :errors="errors"
        />

        <AutoComplete
            id="channel"
            class="m-b-2"
            name="channel"
            label="Channel"
            :items="channelAutocompleteItems"
            placeholder="Select channel"
            @input="onChannelInput"
        />

        <div>
            <Button
                class="notifications__send-button"
                left-icon="broadcast"
                has-border
                :disabled="sendButtonIsDisabled"
                @click="testWebNotification"
            >
                Test notification
            </Button>

            <Button
                class="notifications__send-button"
                left-icon="broadcast"
                has-border
                :disabled="sendButtonIsDisabled"
                @click="showConfirmationDialog = true"
            >
                Send notification to {{ count }} users
            </Button>
        </div>

        <Dialog
            show-confirm-button
            confirm-button-text="Send notification"
            close-button-text="Cancel"
            :is-dialog-visible="showConfirmationDialog"
            :is-loading="$wait.is(LoadingFlag.SendNotification)"
            @onClose="showConfirmationDialog = false"
            @onClickOutside="showConfirmationDialog = false"
            @closeOnEscapeEvent="showConfirmationDialog = false"
            @confirmButtonOnClick="sendNotification"
        >
            <template #header>Send notification</template>
            <template #body>Are you sure you want to send this notification to all users?</template>
        </Dialog>
    </div>
</template>

<script>
import { GetNotificationTokenCountRequest, GetChannelsRequest } from '@/store/models/dtos';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import AutoComplete from '@/components/forms/AutoComplete';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import PageHeading from '@/components/ui/PageHeading';
import { NotificationsClient } from '@/api/notification.ts';
import errorMessages from '@/json/errorMessages.ts';
import { ChannelClient } from '@/api/channel';
import Input from '@/components/forms/Input';
import Button from '@/components/ui/Button';
import Dialog from '@/components/ui/Dialog';
import { isURL } from 'class-validator';

export default {
    name: 'Notifications',
    components: {
        PageHeading,
        Input,
        Button,
        Dialog,
        AutoComplete,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            breadcrumbs: [],
            LoadingFlag,
            title: '',
            body: '',
            image: '',
            channelOptions: [],
            channelCode: '',
            showConfirmationDialog: false,
            count: 0,
            url: '',
            errorMessages,
        };
    },
    computed: {
        channelAutocompleteItems() {
            return this.channelOptions.map((opt) => opt.text);
        },
        sendButtonIsDisabled() {
            return this.title === '' || (this.url !== '' && !isURL(this.url));
        },
        errors() {
            if (this.url) {
                return isURL(this.url) ? [] : [errorMessages.isUrl({ name: 'Notification Url' })];
            }
            return [];
        },
    },
    watch: {
        channelCode(value) {
            this.updateCount(value);
        },
    },
    created() {
        this.setBreadcrumbs();
        this.getChannels();
        this.updateCount();
    },
    methods: {
        onChannelInput(channelName, optionIndex) {
            const channel = this.channelOptions[optionIndex];
            if (channel) {
                this.channelCode = channel.id;
            }
        },
        setBreadcrumbs() {
            this.breadcrumbs = [new Breadcrumb('Notifications', { name: 'notifications' })];
        },
        async getChannels() {
            const request = new GetChannelsRequest();
            request.page = 1;
            request.size = 100;
            const result = await ChannelClient.getChannels(request);
            const channels = result.items.map((channel) => ({
                id: channel.code,
                text: channel.name,
            }));
            this.channelOptions = [{ id: '', text: '--- All channels ---' }, ...channels];
        },
        async updateCount(channelCode = undefined) {
            const request = new GetNotificationTokenCountRequest();
            if (channelCode) {
                request.channelCode = channelCode;
            }
            const result = await NotificationsClient.getNotificationTokenCount(request);
            if (result) {
                this.count = result.count;
            }
        },
        async sendNotification() {
            await NotificationsClient.sendNotification(
                this.title,
                this.body,
                this.url,
                this.image,
                this.channelCode,
            );
            this.showConfirmationDialog = false;
            this.title = '';
            this.body = '';
            this.url = '';
            this.image = '';
            this.channelCode = '';
        },
        async testWebNotification() {
            const self = this;
            const url = this.url.indexOf('http') === 0 ? this.url : `http://${this.url}`;
            if (!('Notification' in window)) {
                alert('This browser does not support desktop notification');
            } else if (Notification.permission === 'granted') {
                const notification = new Notification(this.title, {
                    body: self.body,
                });
                notification.onclick = function () {
                    window.open(url);
                };
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission().then(function (permission) {
                    if (permission === 'granted') {
                        const notification = new Notification(this.title, {
                            body: self.body,
                        });
                        notification.onclick = function () {
                            window.open(url);
                        };
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.notifications {
    display: flex;
    flex-direction: column;

    &__send-button {
        margin-right: 20px;
        align-self: flex-end;
    }
}
</style>
